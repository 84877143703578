<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="500px"
      v-if="resolutionScreen >= 500"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->
      <v-card>
        <v-card-title
          >{{ $t("shortcut_header") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-file-link</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <div v-if="switch1 === true"> -->

          <br />
          <v-text-field
            :style="textfield"
            :color="color.theme"
            prepend-icon="mdi-file-link-outline"
            dense
            outlined
            :label="$t('changeName.filename')"
            v-model="shortcutname"
          ></v-text-field>
          <br />
          <v-textarea
            prepend-icon="mdi-link"
            label="URL"
            :color="color.theme"
            v-model="url_shortcut"
            id="textarea"
            name="input-7-4"
            flat
            outlined
            rows="3"
            hide-details
          ></v-textarea>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('closeshortcut'), cleardata(), $emit('openDrag')"
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="fn_create_shortcut()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("shortcut_header") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closeshortcut'), cleardata(), $emit('openDrag')">mdi-close</v-icon>
            </v-flex>
          </v-layout>
          <span :style="title">{{ $t("changeName.filename") }}</span>
          <v-text-field
            class="mt-1 mb-n4 textFieldClass"
            :color="color.theme"
            :placeholder="$t('changeName.addname')"
            outlined
            dense
            v-model="shortcutname"
            height="35px"
          ></v-text-field>
          <span :style="title">URL</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :placeholder="$t('changeName.addurl')"
            :color="color.theme"
            v-model="url_shortcut"
            id="textarea"
            outlined
            dense
            height="35px"
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="45%"
              color="red"
              outlined
              @click="$emit('closeshortcut'), cleardata(), $emit('openDrag')"
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="45%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="fn_create_shortcut()"
              >{{$t("checkpassword.ok")}}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, url } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    foldername: { required },
    urlErrors: { required },
  },

  name: "onebox_dialogSharefile",
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      url_shortcut: "",
      datetimecheck: "",
      dateymd: "",
      datehour: "",
      dateminute: "",
      shortcutname: "untitled",
      minute: "",
      hour: "",
      picker: "",
      datenow: "",
      modal2: false,
      time_share_link_status: false,
      permission_value: "",
      values_file: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        { name: "edit", key: "04", text: "sharefile.edit" },
        { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_folder: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        { name: "upload", key: "03", text: "sharefile.upload" },
        { name: "edit", key: "04", text: "sharefile.edit" },
        { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      btnsetload: false,
      pleaseinput: false,
      showReload: false,
      link: "",
      loading: true,
      switch1: false,
      foldername: "",
      createprogress: false,
      disable_link: false,
      status_share: "",
      limit_status: false,
      permission_status: false,
      access_limit_count: 0,
      access_limit: 0,
      name_share_th: "",
      name_share_eng: "",
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    urlErrors() {
      const errors = [];
      if (!this.$v.url.$dirty) return errors;
      !this.$v.url.url && errors.push("Must be valid URL");
      !this.$v.url.required && errors.push("URL is required");
      return errors;
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) return errors;
      !this.$v.foldername.required && errors.push("กรุณากรอกชื่อ โฟลเดอร์");
      return errors;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    title() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    textfield () {
      return "min-height: 1px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
  },
  methods: {
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    async fn_create_shortcut() {
      let checkurl = this.validURL(this.url_shortcut);
      console.log("checkurl", checkurl);
      let filename = "untitled";
      if (this.shortcutname !== "") {
        filename = this.shortcutname;
        if (this.url_shortcut === "") {
          Toast.fire({
            icon: "error",
            title: "กรุณากรอก URL ที่ต้องการอัปโหลด",
          });
        } else if (checkurl === false) {
          Toast.fire({
            icon: "error",
            title: "URL ไม่ถูกต้อง",
          });
        } else {
          let folder_id;

          console.log(this.$route);
          if (this.$route.name === "directory") {
            folder_id = this.$route.params.id;
          } else {
            folder_id = this.account_active["directory_id"];
          }
          var payload;
          if(this.dataAccountActive.type === "Citizen"){
            payload = {
            filename: filename,
            folder_id: folder_id,
            user_id: this.dataUsername,
            url_shortcut: this.url_shortcut,
            account_id: this.dataAccountId,
            business_id: "",
          };
          }else{
            payload = {
              filename: filename,
              folder_id: folder_id,
              user_id: this.dataUsername,
              url_shortcut: this.url_shortcut,
              account_id: this.dataAccountId,
              business_id: this.dataAccountActive.business_info.business_id,
            };
          }
          console.log(
            "this.dataAccountActive.business_info.business_id",
            this.dataAccountActive
          );
          let auth = await gbfGenerate.generateToken();
          let response = await this.axios.post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/shortcut",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          );
          console.log(response);
          if (response.data.status === "OK") {
            this.$emit("closeshortcut");
            this.cleardata();
            Toast.fire({
              icon: "success",
              title: "สร้าง shortcut สำเร็จ",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ":" + response.data.errorMessage,
            });
          }
        }
      } else {
        Toast.fire({
          icon: "error",
          title: "กรุณากรอกชื่อไฟล์",
        });
      }
    },
    cleardata() {
      this.$v.$reset();
      this.url_shortcut = "";
      this.shortcutname = "untitled";
    },
  },
};
</script>
<style>
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
